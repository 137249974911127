<template>
    <div>
        <app-header></app-header>
        <app-main></app-main>
        <app-navbar></app-navbar>
    </div>
</template>

<script>
import AppHeader from './AppHeader'
import AppMain from './AppMain'
import AppNavbar from './AppNavbar'
export default {
    components:{
        AppHeader,
        AppMain,
        AppNavbar
    }
}
</script>

<style scoped> /* 头部区域 */
.header {
    background-color: #0F162D;
	width: 100%;
	float: left;
	height: 60px;
	line-height: 60px;
	box-shadow: inset 0px 0px 50px 1px #108fff94;
	border-bottom: 1px solid #74B0FF;
}  
/* 左侧导航栏 */
.navbar { 
    position: absolute; 
    width: 230px;
    top: 60px;
    left: 0px;
    bottom: 0px;
    overflow-y: auto;
    background-color: #0F162D; 
	box-shadow: inset 0px 0px 50px 1px #108fff94;
	border-right: 1px solid #74B0FF;
} 
 /* 右侧主区域 */
.main { 
    position: absolute; 
    top: 60px;
    left: 230px;
    right: 0px; 
    bottom: 0px;
    padding: 20px;
    overflow-y: auto;
    background-color: #0F162D;
}
</style>