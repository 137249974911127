<template>
	<div class="header">
		<!-- <el-button type="primary" plain size="small" class="goHome" @click="goHome">首页</el-button> -->
		<div class="logoAndtitle" @click="goHome">
			<!-- 头部左侧Logo和标题 -->
			<!-- <img class="logo" src="../../assets/logo.png"/>-->
			<span class="company">智慧供热平台</span>
			<div style="width: auto;float: right;">
				<span class="mr-4">{{ dateYear }}<span class="ml-3">{{ dateDay }}</span></span>
				<span class="mr-4">{{ dateWeek }}</span>
				<span class="mr-4">{{ wea }}</span>
				<!-- <span class="mr-4">{{ tem_night }} ~ {{ tem_day }} ℃ </span> -->
			</div>
		</div>
		<el-dropdown @command="handleCommand">
			<span class="el-dropdown-link">
				admin<i class="el-icon-arrow-down el-icon--right"></i>
			</span>
			<el-dropdown-menu slot="dropdown">
				<!--<el-dropdown-item command="a">修改密码</el-dropdown-item>-->
				<el-dropdown-item command="b" @click="loginOut">退出登录</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</div>
</template>

<script>
	import {
		formatTime
	} from '../../utils'

	export default {
		data() {
			return {
				timing: null,
				loading: true,
				dateDay: null,
				dateYear: null,
				dateWeek: null,
				weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
				wea: '',
				tem_night: '',
				tem_day: ''
			}
		},
		mounted() {
			this.timeFn()
			this.getBaodingWeather()
		},
		beforeDestroy() {
			clearInterval(this.timing)
		},
		methods: {
			loginOut() {
				this.$auth.removeToken()
				this.$router.push({
					name: 'login'
				})
			},
			handleCommand(command) {
				if (command == 'b') {
					this.loginOut()
				}
			},
			goHome() {
				this.$router.push({
					name: 'index'
				})
			},
			getBaodingWeather() {
				this.$axios({
					methods: 'get',
					url: 'https://tianqiapi.com/free/day',
					headers: {},
					params: {
						appid: '43656176', // 账号ID
						appsecret: 'I42og6Lm', // 账号密钥
						cityid: '101090201', // 城市编号,不要带CN, 以下参数3选1，当前以西湖区举例
						city: null, // 城市名称,不要带市和区
						ip: null, // IP地址
						callback: 0, // jsonp方式
					},
				}).then((res) => {
					this.wea = res.data.wea;
					this.tem_night = res.data.tem_night;
					this.tem_day = res.data.tem_day;

				}).catch((error) => {
					console.log(error);
				});
			},
			timeFn() {
				this.timing = setInterval(() => {
					this.dateDay = formatTime(new Date(), 'HH: mm: ss')
					this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
					this.dateWeek = this.weekday[new Date().getDay()]
				}, 1000)
			},
		}
	};
</script>

<style lang="scss" scoped>
	.goHome {
		position: absolute;
		left: 50px;
		top: 50%;
		transform: translateY(-50%);
	}

	.logoAndtitle {
		// color: #fff;
		// position: absolute;
		// left: 50%;
		// transform: translateX(-50%);
		color: #fff;
		width: 95%;
		float: left;
	}

	.logo {
		margin-left: 20px;
		width: 26px;
		vertical-align: middle;
		margin-right: 30px;
	}

	.company {
		// margin-left: -20px;
		// margin-right:80px;
		margin-left: 20px;
		margin-right: 70px;
		font-size: 26px;
		font-weight: bold;
		color: #03A9FF;
		cursor: pointer;
	}

	.el-dropdown-link {
		cursor: pointer;
		color: #fff;
	}

	.el-dropdown {
		float: right;
		margin-right: 40px;
	}
</style>
