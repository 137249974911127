import http from '../utils/request';
import * as ria from './ria';
// //getToken
// export const getToken = () => http.post(ria[ 'getToken' ]);
// //realTimeInformation
// export const realTimeInformation = (data) => {
//     return http.get(ria[ 'realTimeInformation'] + "/" + data['biaoshi'] + "/valve?token=" + data['token'])
// };
// export const getProject = (token) => {
//     return http.get(ria[ 'getProject'] + "?token="  + token)
// }

// export const dataLists = (data) => http.get(ria[ 'dataLists'], data)
// //获取曲线
// export const getCurve  = (data) => http.get(ria[ 'getCurve']+"?append="+data.append+"&para_name="+data.para_name+"&start_time="+data.start_time+"&end_time="+data.end_time)
// //普赛列表
// export const psList = (data) => http.get(ria[ 'psList'], {params: data});
// //普赛调节阀门开度
// export const psValve = (data) => http.get(ria[ 'psValve'], {params: data});
// //普赛详情
// export const psDetail = (data) => http.get(ria[ 'psDetail'], {params: data});
// //普赛历史
// export const psHistory = (data) => http.get(ria[ 'psHistory'], {params: data});
// //泰格尔列表
// export const tgeList = (data) => http.get(ria[ 'tgeList'], {params: data});
// //泰格尔阀门开度
// export const tgeValve = (data) => http.get(ria[ 'tgeValve'], {params: data});
// //泰格尔详情
// export const tgeDetail = (data) => http.get(ria[ 'tgeDetail'], {params: data});
// //泰格尔历史数据
// export const tgeHistory = (data) => http.get(ria[ 'tgeHistory'], {params: data});
// //天罡列表
// export const tgList = (data) => http.get(ria[ 'tgList'], {params: data});
// //天罡阀门开度
// export const tgValve = (data) => http.get(ria[ 'tgValve'], {params: data});
// //天罡详情
// export const tgDetail = (data) => http.get(ria[ 'tgDetail'], {params: data});
// //天罡历史数据
// export const tgHistory = (data) => http.get(ria[ 'tgHistory'], {params: data});
// //首页回水温度供水温度统计
// export const homeData = (data) => http.get(ria[ 'homeData'], {params: data});
// //泰得尔温度
// export const tdeTemperature = (data) => http.get(ria[ 'tdeTemperature'], {params: data});
// //普赛温度
// export const psTemperature = (data) => http.get(ria[ 'psTemperature'], {params: data});
// //天罡温度
// export const tgTemperature = (data) => http.get(ria[ 'tgTemperature'], {params: data});

// //泰得尔曲线
// export const tdeCurve = (data) => http.get(ria[ 'tdeCurve'], {params: data});
// //普赛曲线
// export const psCurve = (data) => http.get(ria[ 'psCurve'], {params: data});
// //天罡曲线
// export const tgCurve = (data) => http.get(ria[ 'tgCurve'], {params: data});
// //换热站
// export const heatExchangeStation = (data) => http.get(ria[ 'heatExchangeStation'], {params: data});
// //提交换热站
// export const submitHeatExchangeStation = (data) => http.get(ria[ 'submitHeatExchangeStation'], {params: data});
// //获取参数设置
// export const getParameterSetting = (data) => http.get(ria[ 'getParameterSettingInfo'], {params: data});
// //提交参数设置
// export const submitParameterSetting = (data) => http.get(ria[ 'submitParameterSettingInfo'], {params: data});
// //修改补偿回水温度
// export const updateCompensationWaterTemperature = (data) => http.get(ria[ 'updateCompensationWaterTemperatureInfo'], {params: data});
// //获取小区名称、换热站、分公司、单元号下拉选值普赛
// export const getPsSelectValue = (data) => http.get(ria[ 'getPsSelectValue'], {params: data});
// //获取小区名称、换热站、分公司、单元号下拉选值泰得尔
// export const getTdeSelectValue = (data) => http.get(ria[ 'getTgeSelectValue'], {params: data});
// //获取小区名称、换热站、分公司、单元号下拉选值天罡
// export const getTgSelectValue = (data) => http.get(ria[ 'getTgSelectValue'], {params: data});

// 获取菜单
export const getMenu = (data) => http.get(ria['getMenulist'], { params: data });
// 换热站
export const listData = (data) => http.get(ria['listData'], { params: data });
// 表格数据
export const tableData = (data) => http.get(ria['tableData'], { params: data });
// 曲线数据
export const circleData = (data) => http.get(ria['circleData'], { params: data });

export const homePage=(params)=>http.get('f/zhpt/ewShengmanageStation/homePage',{params})
