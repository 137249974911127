const TOKEN_KEY = 'token';
// 获取 token
export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

// 设置 token
export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

//移除token和用户信息
export function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
}
