<template>
	<!-- router 开启路由功能,则 index指定路由地址
default-active 默认选中哪个菜单,选中为黄色, 注意 v-bind:default-active 才可以指定表达式 -->
	<div class="navbar">
		<el-menu :router="false" :default-active="$route.path" class="el-menu-vertical-demo" background-color="#66b1ff00"
			text-color="#E5F6FF" active-text-color="#E5F6FF">
			<!-- 一级菜单 -->
		    <template v-for="item in menuList">
		        <el-submenu v-if="item.children && item.children.length" :index="item.index" :key="item.id">
					<template slot="title"><span>{{item.name}}</span></template>
					<!-- 二级菜单 -->
					<template v-for="itemChild in item.children">
						<el-submenu v-if="itemChild.children && itemChild.children.length" :index="itemChild_Child.index" :key="itemChild.index">
							<template slot="title" class="title"><span>{{itemChild.name}}</span></template>
							<!-- 三级菜单 -->
							<el-menu-item v-for="itemChild_Child in itemChild.children" :index="itemChild_Child.index" :key="itemChild_Child.index">
								<span slot="title" class="title">{{itemChild_Child.name}}</span>
							</el-menu-item>
						</el-submenu>
						<el-menu-item v-else :index="itemChild.index" @click="goList(itemChild.name, itemChild.index)">
							<span slot="title">{{itemChild.name}}</span>
						</el-menu-item>
					</template>
		        </el-submenu>
		        <el-menu-item v-else :index="item.index" :key="item.index"><span slot="title">{{item.name}}</span></el-menu-item>
		    </template>
		</el-menu>
	<!--  -->
	</div>
	
</template>

<script>
	import { getMenu } from "../../assets/js/api";
	export default {
		data() {
			return {
				menuList: []
			}
		},
		mounted() {
			this.getMenulist()
		},
		methods: {
			getMenulist() {
				getMenu().then(res => {
					this.menuList = res.data.menuList
				})
			},
			goList(name, id){
				if(name == "中海澜庭"){
					this.$router.push({
						name:"ccList",
						query: {id: id}
					},()=>{},()=>{})
				}else{
					this.$router.push({
						name:"list",
						query: {id: id}
					},()=>{},()=>{})
				}
				
			}
		}
	};
</script>
<style scoped>
	.el-menu {
		border-right: none;
		background-color: #ffffff00;
		border-right: 0;
	}
	/deep/.el-submenu .el-menu {
		background-color: #ffffff00;
	}
	/deep/.el-submenu__title {
		font-size: 16px;
	}
	/deep/.el-menu-item-group__title {
		padding: 0;
	}
	.el-menu-item.is-active {
		background-image: linear-gradient(to right, #0066E3, #00B5FF) ! important;
	}
	.el-submenu>.el-menu {
		background-color: #ffffff00;
	}
	*::-webkit-scrollbar {width:4px; height:8px; background-color:transparent;} /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
	*::-webkit-scrollbar-track {background-color: rgba(117,116,116,0.3);  } /*定义滚动条轨道 内阴影+圆角*/
	*::-webkit-scrollbar-thumb {background-color:rgba(0,0,0,0.3); border-radius:6px;} /*定义滑块 内阴影+圆角*/
	.scrollbarHide::-webkit-scrollbar{display: none}
	.scrollbarShow::-webkit-scrollbar{display: block}
</style>
