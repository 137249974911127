import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/Layout.vue"
import { getToken } from '@/assets/js/auth.js'
Vue.use(VueRouter)

const beforeEnter = (to, from, next) => {
    const checkAuth = getToken();
    if (checkAuth != "1wVFX6Bmsx") {
        window.location.href = "/login"
        return;
    }
    next();
};

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/login.vue')
    },
    {
        path: '/index',
        name: 'index',
        beforeEnter,
        component: () => import('../views/index.vue')
      },
    {
        path:'/layout',
        name:'layout',
        component: Layout,
        beforeEnter,
        children:[
			{
			    path:'/layout/hrz/list',
			    name:'list',
			    component:  () => import('../views/hrz/list.vue'),
			    meta:{title: "换热站列表"}
			},
			{
			    path:'/layout/hrz/ccList',
			    name:'ccList',
			    component:  () => import('../views/hrz/ccList.vue'),
			    meta:{title: "换热站列表"}
			},
            // {
            //     path:'/layout/instruct/tgeList',
            //     name:'instructTgeList',
            //     component:  () => import('../views/instruct/tgeList.vue'),
            //     meta:{title: "泰德尔列表"}
            // },
            // {
            //     path:'/layout/instruct/psList',
            //     name:'instructpsList',
            //     component:  () => import('../views/instruct/psList.vue'),
            //     meta:{title: "普赛列表"}
            // },
            // {
            //     path:'/layout/instruct/tgList',
            //     name:'instructtgList',
            //     component:  () => import('../views/instruct/tgList.vue'),
            //     meta:{title: "天罡列表"}
            // },
            // {
            //     path:'/layout/dataList/tgeList',
            //     name:'tgeList',
            //     component:  () => import('../views/layout/dataList/tgeList'),
            //     meta:{title: "泰德尔列表"}
            // },
            // {
            //     path:'/layout/dataList/psList',
            //     name:'psList',
            //     component:  () => import('../views/layout/dataList/psList'),
            //     meta:{title: "普赛列表"}
            // },
            // {
            //     path:'/layout/dataList/tgList',
            //     name:'tgList',
            //     component:  () => import('../views/layout/dataList/tgList'),
            //     meta:{title: "天罡列表"}
            // },
            // {
            //     path:'/layout/visual',
            //     name:'visual',
            //     component:  () => import('../views/layout/Visual.vue'),
            //     meta:{title: "区域可视化"}
            // },
            // {
            //     path:'/layout/history',
            //     name:'history',
            //     component:  () => import('../views/layout/History.vue'),
            //     meta:{title: "历史曲线展示"}
            // },
            // {
            //     path:'/layout/history/tgeList',
            //     name:'historyTgeList',
            //     component:  () => import('../views/layout/history/tgeList'),
            //     meta:{title: "泰德尔列表"}
            // },
            // {
            //     path:'/layout/history/psList',
            //     name:'historyPsList',
            //     component:  () => import('../views/layout/history/psList'),
            //     meta:{title: "普赛列表"}
            // },
            // {
            //     path:'/layout/history/tgList',
            //     name:'historyTgList',
            //     component:  () => import('../views/layout/history/tgList'),
            //     meta:{title: "天罡列表"}
            // },
        ]
    },
]
const router = new VueRouter({
  routes
})

export default router