import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
import ElementUI from 'element-ui';
import BaiduMap from 'vue-baidu-map'
import axios from 'axios'
import * as auth from './assets/js/auth.js';
// 引入全局css
import './assets/scss/style.scss';
import 'element-ui/lib/theme-chalk/index.css';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import mitt from 'mitt'
//引入echart
//4.x 引用方式
import echarts from 'echarts'
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios
Vue.prototype.$auth = auth;
Vue.config.productionTip = false;
Vue.prototype.$event = mitt()

//   this.$event.on('a', (d) => {
//     console.log(d)
//   })
//   this.$event.emit('a', 'bbbbb')
// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);
Vue.use(ElementUI);
Vue.use(BaiduMap, {
  ak: 'rQxHPQX1ua21rWnXzTbUh5KSSUbnefxR'
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
