//
// export const getToken = '/login?login=dtcrjtrljk2011&password= n6460f'

// export const realTimeInformation = "/valve"

// export const getProject = "/projects"

// export const dataLists = "/f/getData"

// export const getCurve = "f/getHisData"

// export const psList = "f/ps/listData"

// export const psValve = "f/ps/openValve"

// export const psDetail = "f/ps/getData"

// export const psHistory = "f/ps/historyListData"

// export const tgeList = "f/tiger/listData"

// export const tgeValve = "f/tiger/openValve"

// export const tgeDetail = "f/tiger/getData"

// export const tgeHistory = "f/tiger/historyListData"

// export const tgList = "f/tz/listData"

// export const tgValve = "f/tz/openValve"

// export const tgDetail = "f/tz/getData"

// export const tgHistory = "f/tz/historyListData"

// // export const homeData = "f/ps/getAVGHeatByHrz"
// export const homeData = "f/common/getAVGHeatByHrz"

// export const tdeTemperature = "f/tiger/getAVGHeat"

// export const psTemperature = "f/ps/getAVGHeat"

// export const tgTemperature = "f/tz/getAVGHeat"

// export const tdeCurve = "f/ew/tigerValveHis/historyListData"

// export const psCurve = "f/ew/psValveHis/historyListData"

// export const tgCurve = "f/ew/tzValveHis/historyListData"

// // export const heatExchangeStation = "f/ps/getHrzList"
// export const heatExchangeStation = "f/common/getHrzList"


// export const submitHeatExchangeStation = "f/common/hswdControl"

// export const getParameterSettingInfo = "f/common/getParamter"

// export const submitParameterSettingInfo = "f/common/saveParamter"

// export const updateCompensationWaterTemperatureInfo = "f/common/updateBchswd"

// export const getPsSelectValue = "f/ps/dictList"

// export const getTgeSelectValue = "f/tiger/dictList"

// export const getTgSelectValue = "f/tz/dictList"

export const getMenulist = "f/zhpt/findMenu"  
export const listData = "f/zhpt/ewShengmanageStation/listData"
export const tableData = "f/zhpt/ewShengmanageMachine/listData"
export const circleData = "f/zhpt/ewShengmanageMachine/findFmycList"
