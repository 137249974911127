import axios from 'axios';
import { Loading, Message } from 'element-ui';
// let baseUrl = "http://localhost:8979/ew";
// let baseUrl = "/ew"; //正式地址 
// let baseUrl = "http://101.42.118.206:20002/syspt";  //206测试地址
let baseUrl = "/syspt";  // 龙生地址
// let baseUrl = "http://111.40.119.52:20002/syspt";  // 龙双地址
// let baseUrl = "http://localhost:20002/syspt";  //本地地址


let requestCount = 0;

const loading = {
    loadingInstance: null,
    open() {
        if (this.loadingInstance === null) {
            this.loadingInstance = Loading.service(
                {
                    text: '拼命加载中...',
                    target: 'body',
                    background: 'rgba(255,255,255,0.3)',
                    fullscreen: true
                }
            );
        }
    },
    close() {
        if (this.loadingInstance !== null) {
            this.loadingInstance.close();
        }

        this.loadingInstance = null;
    }
};

const addRequest = () => {
    requestCount += 1;
    loading.open();
};

const delRequest = () => {
    requestCount -= 1;

    if (requestCount <= 0) {
        requestCount = 0;
        loading.close();
    }
};


const request = axios.create({
    baseURL: `${ baseUrl }`,
    timeout: 50000
});
//屏蔽TOKEN URL
const shieldUrls = [
    'login',
    'captchaImage'
];
const setConfig = (config = {}) => {
    let dataType = ['post', 'put'].includes(config['method']) ? 'data' : 'params';

    if (typeof config[dataType] === 'undefined') {
        config[dataType] = {};
    }
};
request.interceptors.request.use((config) => {
    addRequest();

    setConfig(config);
    return config;
}, function (error) {
    delRequest();
    return Promise.reject(error);
});

request.interceptors.response.use((response) => {
    delRequest();
    const resp = response.data;
    if(resp.code){
        if (resp.code !== 200) {
            Message.error(resp.msg);
        }
    }
    return response;
}, function (error) {
    delRequest();
    return Promise.reject(error);
});

export default request;
